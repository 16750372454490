import {
   
    NAAMJAAM_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    EXPORT_NAAMJAM_DATA
  } from "./actionType";
  
  // common success

  export const NaamJaamApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  export const NaamJaamApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getNaamJamRequest = payload => ({
    type: NAAMJAAM_LIST,
    payload,
  });

  export const exportNaamJamdata = payload => ({
    type: EXPORT_NAAMJAM_DATA,
    payload,
  });


  