import {
   
EVENT_LIST
  } from "./actionType";
  
  // common success

  export const EventsListRequest = payload => ({
    type: EVENT_LIST,
    payload: payload,
  });