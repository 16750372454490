import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isInactiveMembers, setInactiveMembers] = useState(false);
    const [isInactiveMembersRequest, setInactiveMembersRequest] = useState(false);
    const [isNaamjamreportLink, setnaamjamreportLink] = useState(false);
    const [issuggestionsLink, setSuggestionsLink] = useState(false);
    const [isEventLink, setEventLink] = useState(false);
    const [isHumanityUserLink, setHumanityUserLink] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Inactive Members') {
            setInactiveMembers(false);
        }
        if (iscurrentState !== 'Naam-Jaam Report') {
            setnaamjamreportLink(false);
        }
        if (iscurrentState !== 'Suggestions') {
            setSuggestionsLink(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Home",
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            }
        },
        {
            id: "humanityusers",
            label: "Humanity Users",
            icon: <FeatherIcon icon="users" className="icon-dual" />,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setHumanityUserLink(!isHumanityUserLink);
                setIscurrentState('Humanity Users');
                updateIconSidebar(e);
            },
            stateVariables: isHumanityUserLink,
            subItems: [
                {
                    id: "inactivemembers",
                    label: "Inactive Members",
                    link: "/inactive_members",
                    
                    click: function (e) {
                        e.preventDefault();
                        setInactiveMembers(!isInactiveMembers);
                        setIscurrentState('Inactive Members');
                        updateIconSidebar(e);
                    },
                    parentId: "authentication",
                    
                },
                {
                    id: "inactiveMembersReq",
                    label: "Inactive Members Request",
                    link: "/inactive_members_request",
                    
                    click: function (e) {
                        e.preventDefault();
                        setInactiveMembersRequest(!isInactiveMembersRequest);
                        setIscurrentState('Inactive Members Request');
                        updateIconSidebar(e);
                    },
                    parentId: "authentication",
                    
                },
               
          
            ],
        },
        {
            id: "naamjaamreport",
            label: "Naam-Jaam Report",
            icon: <FeatherIcon icon="file-text" className="icon-dual" />,
            link: "/naamjaam_report",
            stateVariables: isNaamjamreportLink,
            click: function (e) {
                e.preventDefault();
                setnaamjamreportLink(!isNaamjamreportLink);
                setIscurrentState('Naam-Jaam Report');
                updateIconSidebar(e);
            }
        },
        {
            id: "suggestions",
            label: "Suggestions",
            icon: <FeatherIcon icon="archive" className="icon-dual" />,
            link: "/suggestions_list",
            stateVariables: issuggestionsLink,
            click: function (e) {
                e.preventDefault();
                setSuggestionsLink(!issuggestionsLink);
                setIscurrentState('Suggestions');
                updateIconSidebar(e);
            }
        },
        {
            id: "events",
            label: "Events",
            icon: <FeatherIcon icon="command" className="icon-dual" />,
            link: "/event_list",
            stateVariables: isEventLink,
            click: function (e) {
                e.preventDefault();
                setEventLink(!isEventLink);
                setIscurrentState('Events');
                updateIconSidebar(e);
            }
        },
    
        
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;