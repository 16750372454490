import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";



//API Key
import APIKeysaga from "./apikey/saga";
import humanityuserSaga from "./Humanity_users/saga";
import NaamJaamSaga from "./Meditation/saga";
import SuggestionSaga from "./suggestion/saga";
import EventSaga from "./events/saga";
import CommonSaga from "./common/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(dashboardEcommerceSaga),
    fork(APIKeysaga),
    fork(humanityuserSaga),
    fork(NaamJaamSaga),
    fork(SuggestionSaga),
    fork(EventSaga),
    fork(CommonSaga),
  ]);
}
