import React, { useState, useEffect,useCallback }  from 'react';
import { Link } from 'react-router-dom';
import { Button,Card, CardBody, CardHeader, Col, Container, Row ,Modal, ModalHeader, ModalBody, Toast, ToastBody, ToastHeader,Input,Label} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { getSuggestionRequest ,exportSuggestiondata} from "../../store/suggestion/action";
import {useDispatch} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import moment  from 'moment';
import Select from "react-select";
import { EventsListRequest } from '../../store/events/action';
import { GroupListRequest } from '../../store/actions';

const EventListView = () => {
 
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rows,setRows]=useState([]);
    const [reactiveID,setReactiveID]=useState();
    const [groupList,setGroupList]=useState([])
    const countPerPage = 10;


    const columns = [
      
      {
        name: 'Title',
        selector: (row, i) => row.title
        
      },
      {
        name: 'Start From',
        selector: (row, i) => moment(row.fromDate).format("DD-MM-YYYY") 
      },
      {
        name: 'Location',
        selector: (row, i) => row.eventLocation
      },
      {
        name:"Add Sewadar",
        cell: (row) => (
            <>
            <Button onClick={() => handleButtonClick(row)} color="warning" className="btn-icon"> <i className="ri-user-add-fill" /> </Button>
          
          </>
        ),
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
      
    ];

    const [modal_center, setmodal_center] = useState(false);
    const [searchdata, setSearchdata] = useState("");
    const [gender, setGender] = useState("");
    const [eventType, setEventType] = useState("upcoming");
    const [selectedGroup,setSelectedGroup]=useState(1);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const handleButtonClick = (e, row) => {
      //e.preventDefault();
      setRows(e);
      setmodal_center(!modal_center);
    };

   
    const getEventList= useCallback(()=>{
     
   
        dispatch(
          
          EventsListRequest(
              {payload:{ pageno: page,countPerPage:countPerPage,gender:gender,eventType,selectedGroup},
              onSuccess: (response) => {
               // console.log("response data",response.data);
                if (response.data) {
                  setData(response);
                  console.log(response)
               //   pageRef.current = listdata[listdata.length - 1].sync_Timestamp;
                } else {
                //  hasMoreRef.current = false;
                }
              },
              onFinally: () => {
                //setLoading(false);
                //setRefreshing(false);
                console.log("final called");
              },
            }),
         );
    },[dispatch,page,gender,eventType,selectedGroup],
       
    );
    const getGroupList= useCallback(()=>{
     
   
      dispatch(
        
        GroupListRequest(
            {payload:{},
            onSuccess: (response) => {
            
              if (response.data) {
                setGroupList(response.data);
                console.log("response",response)
           
              } 
            },
            onFinally: () => {
              //setLoading(false);
              //setRefreshing(false);
              console.log("final called");
            },
          }),
       );
  },[dispatch],
     
  );

 
      useEffect(() => {
        getEventList();
        getGroupList();
      }, [getEventList,getGroupList]);

    const _Updategender=sex=>
    {
       setGender(sex);
    }

    const _UpdateEventType=eventtype=>
    {
       setEventType(eventtype);
    }
    const _UpdateSelectedGroup=groupid=>
    {
      setSelectedGroup(groupid);
    }

   

    document.title = "Event List | HUManITy";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Event List" pageTitle="Home" />
                            
                    <Row>
                        <Col lg={12}>
                            <Card>
                                
                                <CardBody>
                               
                                <Row className="g-4 mb-3">
                                <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">Gender</label></div>
                                  <select className="form-select mb-3" aria-label="Default select example" onChange={e=>_Updategender(e.target.value)} >
                                      <option defaultValue="all">Select gender </option>
                                      <option defaultValue="male">Male</option>
                                      <option defaultValue="female">Female</option>
                                      <option defaultValue="sukhdua">Sukhdua</option>
                                  </select>
                              </Col>
                                <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">Event Type</label></div>
                                  <select className="form-select mb-3" aria-label="Default select example" onChange={e=>_UpdateEventType(e.target.value)} >
                                      <option defaultValue="upcoming">upcoming </option>
                                      <option defaultValue="past">past</option>
                                      <option defaultValue="ongoing">ongoing</option>

                                  </select>
                              </Col>
                              <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">Select Group</label></div>
                                  <select className="form-select mb-3" aria-label="Default select example" onChange={e=>_UpdateSelectedGroup(e.target.value)} >
                                      {groupList.map((groupItem)=>{
                                        return (<option defaultValue="upcoming" key={groupItem.group_id} value={groupItem.group_id} >{groupItem.group_name} </option>)
                                      })}

                                  </select>
                              </Col>
                                </Row>
                            
                             
                                <DataTable
                                    columns={columns}
                                    data={data.data}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={data.totalrows}
                                    paginationPerPage={countPerPage}
                                    paginationComponentOptions={{
                                    noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </div>
            <Modal
        isOpen={modal_center}
        toggle={() => {
            tog_center();
        }}
        centered
    >
      
        <ModalHeader>
           
        </ModalHeader>
        <div className="modal-body text-center p-5">
        <img height="150px" width="150px" alt={rows.usrname} src={"https://fileupload.dsshumanityapp.com/profile/"+rows.usrid+".jpg"} />
            <div className="mt-4">
            <h4 className="mb-3">{rows.usrname}</h4>
                <h5 className="mb-3">Do you want to Re-Activate this?</h5>
                <p className="text-muted mb-4"> Once You will re-active.Member will able to login.</p>
                <div className="hstack gap-2 justify-content-center">
                    <Button color="light" onClick={() => setmodal_center(false)}>No</Button>
                   
                </div>
            </div>
        </div>
    </Modal>
        </React.Fragment>
    );
};

export default EventListView;
