import {
   
    SUGGESTIONS_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    EXPORT_SEGGESTION_DATA
  } from "./actionType";
  
  // common success

  export const SuggestionApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  export const SuggestionApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getSuggestionRequest = payload => ({
    type: SUGGESTIONS_LIST,
    payload,
  });

  export const exportSuggestiondata = payload => ({
    type: EXPORT_SEGGESTION_DATA,
    payload,
  });


  