import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Ecoomerce Redux States
import {
    INACTIVE_USER,
    REACTIVE_USER
   
} from "./actionType";

import {
  getInactiveMembersRequest,
  HumanityApiResponseError,
  HumanityApiResponseSuccess,
  re_activateMemberRequest,
  
} from "./action";

//Include Both Helper File with needed methods
import {
    getInactiveMembers as getInactiveMembersAPI,
    ReactiveMembersAPI
} from "../../helpers/fakebackend_helper";

function* getInactiveMem({payload: {payload, onFinally, onSuccess} = {},
}) {
  try 
  {
    const response = yield call(getInactiveMembersAPI,payload);
    onSuccess(response);
    yield put(HumanityApiResponseSuccess(INACTIVE_USER, response));
  } catch (error) {
    yield put(HumanityApiResponseError(INACTIVE_USER, error));
  }
}

export function* watchGetInactiveMember() {
  yield takeEvery(INACTIVE_USER, getInactiveMem);
}

function* re_ActivateMem({payload: {payload, onFinally, onSuccess} = {},
}) {
  try 
  {
    const response = yield call(ReactiveMembersAPI,payload);
    onSuccess(response);
    yield put(HumanityApiResponseSuccess(REACTIVE_USER, response));
  } catch (error) {
    yield put(HumanityApiResponseError(REACTIVE_USER, error));
  }
}

export function* watchRe_AActiveMember() {
  yield takeEvery(REACTIVE_USER, re_ActivateMem);
}


function* humanityuserSaga() {
  yield all([
    fork(watchGetInactiveMember),
    fork(watchRe_AActiveMember),
  ]);
}

export default humanityuserSaga;
