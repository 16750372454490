import {
    INACTIVE_USER,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
  } from "./actionType";
  
  const INIT_STATE = {
    data: [],
    orders: [],
    sellers: [],
    customers: [],
    error: {},
  };
  
  const HumanityUser = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
          
          case INACTIVE_USER:
            return {
              ...state,
              orders: action.payload.data,
              isOrderCreated: false,
              isOrderSuccess: true
            };
         
          default:
            return { ...state };
        }
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          
          case INACTIVE_USER:
            return {
              ...state,
              error: action.payload.error,
              isOrderCreated: false,
              isOrderSuccess: false
            };
         
          default:
            return { ...state };
        }
  
     
  
      case INACTIVE_USER: {
        return {
          ...state,
          isOrderCreated: false,
        };
      }
     
  
      default:
        return { ...state };
    }
  };
  
  export default HumanityUser;