import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Ecoomerce Redux States
import {
    NAAMJAAM_LIST,
    EXPORT_NAAMJAM_DATA

} from "./actionType";

import {
  NaamJaamApiResponseSuccess,
  NaamJaamApiResponseError,
  
} from "./action";

//Include Both Helper File with needed methods
import {
    NaamJaamlistAPI,
    NaamJaamDownloadAPI
} from "../../helpers/fakebackend_helper";

function* getNaamJamSaga({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getNaamJamSaga");
  try 
  {
    const response = yield call(NaamJaamlistAPI,payload);
    onSuccess(response);
    yield put(NaamJaamApiResponseSuccess(NAAMJAAM_LIST, response));
  } catch (error) {
    yield put(NaamJaamApiResponseError(NAAMJAAM_LIST, error));
  }
}

function* exxportNaamJaamExcel({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getNaamJamSaga");
  try 
  {
    const response = yield call(NaamJaamDownloadAPI,payload);
    onSuccess(response);
    yield put(NaamJaamApiResponseSuccess(NAAMJAAM_LIST, response));
  } catch (error) {
    yield put(NaamJaamApiResponseError(NAAMJAAM_LIST, error));
  }
}

export function* watchgetNaamJamSaga() {
  yield takeEvery(NAAMJAAM_LIST, getNaamJamSaga);
  yield takeEvery(EXPORT_NAAMJAM_DATA, exxportNaamJaamExcel);
}



function* NaamJaamSaga() {
  yield all([
    fork(watchgetNaamJamSaga),
  ]);
}

export default NaamJaamSaga;
