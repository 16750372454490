// Layout
export * from "./layouts/action";
// Authentication module
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/profile/actions";
//calendar

//ecommerce
export * from "./ecommerce/action";

//API Key
export * from "./apikey/action";
export * from "./Humanity_users/action";
export * from "./Meditation/action";
export * from "./suggestion/action";
export * from "./events/action";
export * from "./common/action";