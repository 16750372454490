import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Ecoomerce Redux States
import {
    GROUP_LIST,

} from "./actionType";

import {
  SuggestionApiResponseSuccess,
  SuggestionApiResponseError,
  
} from "./action";

//Include Both Helper File with needed methods
import {
     GroupListAPI,
} from "../../helpers/fakebackend_helper";

function* getGroupListSaga({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getGroupListSaga",payload);
  try 
  {
    const response = yield call(GroupListAPI,payload);
    onSuccess(response);
  
  } catch (error) {
      console.log(error);
  }
}



export function* watchCommonSaga() {
  yield takeEvery(GROUP_LIST, getGroupListSaga);
}

function* CommonSaga() {
  yield all([
    fork(watchCommonSaga),
  ]);
}

export default CommonSaga;
