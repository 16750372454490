import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Ecommerce
import Ecommerce from "./ecommerce/reducer";





// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

//API Key
import APIKey from "./apikey/reducer";
import HumanityUser from "./Humanity_users/reducer";
import Meditation from "./Meditation/reducer";
import Suggestion from "./suggestion/reducer";
const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Ecommerce,
  
    DashboardProject,
    APIKey,
    HumanityUser,
    Meditation,
    Suggestion
});

export default rootReducer;