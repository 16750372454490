import React, { useState, useEffect,useCallback }  from 'react';
import { Link } from 'react-router-dom';
import { Button,Card, CardBody, CardHeader, Col, Container, Row ,Modal, ModalHeader, ModalBody, Toast, ToastBody, ToastHeader,} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { getInactiveMembersRequest,re_activateMemberRequest } from "../../store/Humanity_users/action";
import {useDispatch} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

const InactiveMemberRequestList = () => {
  const bottomrightnotify = () => toast("Welcome Back ! This is a Toast Notification", { position: "bottom-right", hideProgressBar: true, className: 'bg-success text-white' });

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rows,setRows]=useState([]);
    const [reactiveID,setReactiveID]=useState();
    
    const countPerPage = 10;


    const columns = [
      {
        name: 'Avatar',
        cell: row => <img height="30px" width="30px" alt={row.usrname} onError={(e) => {
        e.target.src =require('../../assets/images/users/user-dummy-img.jpg')}}
          src={"https://fileupload.dsshumanityapp.com/profile/"+row.usrid+".jpg"} />,
      },
      {
        name: 'Name',
        selector: (row, i) => row.usrname
        
      },
      {
        name: 'Mobile',
        selector: (row, i) => row.usrmob
      },
      {
        name: 'Email',
        selector: (row, i) => row.usrpriemail
      },
      {
        name: 'Block',
        selector: (row, i) => row.blockname
      },
      {
        name: 'State',
        selector: (row, i) => row.statename
      },
      {
        name: 'Country',
        selector: (row, i) => row.countryname
      },
      
      
      
      {
        name: 'DUTY',
        selector: (row, i) => row.categoryname
      },
      {
        name:"Re-Active",
        cell: (row) => (
            <>
            <Button onClick={() => handleButtonClick(row)} color="success" className="btn-icon"> <i className="ri-check-double-line" /> </Button>
          
          </>
        ),
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
    ];

    const [modal_center, setmodal_center] = useState(false);
    const [searchdata, setSearchdata] = useState("");
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const handleButtonClick = (e, row) => {
      //e.preventDefault();
      setRows(e);
      setmodal_center(!modal_center);
    };

   const onReActivePress=(rows)=>{
  
      dispatch(
        re_activateMemberRequest(
          {payload:{ usrid: rows.usrid},
          onSuccess: (response) => {
           // console.log("response data",response.data);
            if (response.success) {
             
              setmodal_center(false);
              
              toast(response.message, { position: "bottom-right", hideProgressBar: true, className: 'bg-success text-white' });
              const arrayCopy = data.data.filter((row) => row.usrid !== rows.usrid);
              console.log("finaldata",arrayCopy);
              data.data=arrayCopy;
              setData(data);

            } else {
            //  hasMoreRef.current = false;
            }
          },
          onFinally: () => {
            //setLoading(false);
            //setRefreshing(false);
          },
        }),
     );
    
   }
 

   
    const getUserList= useCallback(()=>{
     
     
        dispatch(
            getInactiveMembersRequest(
              {payload:{ pageno: page,countPerPage:countPerPage,searchdata:searchdata,type:"request"},
              onSuccess: (response) => {
               // console.log("response data",response.data);
                if (response.data) {
                  setData(response);
               //   pageRef.current = listdata[listdata.length - 1].sync_Timestamp;
                } else {
                //  hasMoreRef.current = false;
                }
              },
              onFinally: () => {
                //setLoading(false);
                //setRefreshing(false);
              },
            }),
         );
    },[dispatch,page,searchdata],
       
    );
 
    
     
      useEffect(() => {
        getUserList();
      }, [getUserList]);




    document.title = "Inactive Members Request List | HUManITy";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Inactive Members Request" pageTitle="Home" />
                            
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">List Of All Inactive Members</h5>
                                </CardHeader>
                              
                                <CardBody>
                                <Row className="g-4 mb-3">
                                            
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." onChange={e => setSearchdata(e.target.value)} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                <DataTable
                                    columns={columns}
                                    data={data.data}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={data.totalrows}
                                    paginationPerPage={countPerPage}
                                    paginationComponentOptions={{
                                    noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </div>
            <Modal
        isOpen={modal_center}
        toggle={() => {
            tog_center();
        }}
        centered
    >
      
        <ModalHeader>
           
        </ModalHeader>
        <div className="modal-body text-center p-5">
        <img height="150px" width="150px" alt={rows.usrname} src={"https://fileupload.dsshumanityapp.com/profile/"+rows.usrid+".jpg"} onError={(e) => {
        e.target.src =require('../../assets/images/users/user-dummy-img.jpg')}} />
            <div className="mt-4">
            <h4 className="mb-3">{rows.usrname}</h4>
                <h5 className="mb-3">Do you want to Re-Activate this?</h5>
                <p className="text-muted mb-4"> Once You will re-active.Member will able to login.</p>
                <div className="hstack gap-2 justify-content-center">
                    <Button color="light" onClick={() => setmodal_center(false)}>No</Button>
                    <Link   onClick={(e) => onReActivePress(rows)}  className="btn btn-primary">Re-Activate</Link>
                </div>
            </div>
        </div>
    </Modal>
        </React.Fragment>
    );
};

export default InactiveMemberRequestList;
