import {
   
    GROUP_LIST
      } from "./actionType";
      
      // common success
    
      export const GroupListRequest = payload => ({
        type: GROUP_LIST,
        payload: payload,
      });