import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Ecoomerce Redux States
import {
    SUGGESTIONS_LIST,
    EXPORT_SEGGESTION_DATA

} from "./actionType";

import {
  SuggestionApiResponseSuccess,
  SuggestionApiResponseError,
  
} from "./action";

//Include Both Helper File with needed methods
import {
    SuggestionListAPI,
    SuggestionDownloadAPI
} from "../../helpers/fakebackend_helper";

function* getSuggestionSaga({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getSuggestionSaga");
  try 
  {
    const response = yield call(SuggestionListAPI,payload);
    onSuccess(response);
    yield put(SuggestionApiResponseSuccess(SUGGESTIONS_LIST, response));
  } catch (error) {
    yield put(SuggestionApiResponseError(SUGGESTIONS_LIST, error));
  }
}

function* exportSuggestionExcel({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getSuggestionSaga");
  try 
  {
    const response = yield call(SuggestionDownloadAPI,payload);
    onSuccess(response);
    yield put(SuggestionApiResponseSuccess(SUGGESTIONS_LIST, response));
  } catch (error) {
    yield put(SuggestionApiResponseError(SUGGESTIONS_LIST, error));
  }
}

export function* watchgetSuggestionSaga() {
  yield takeEvery(SUGGESTIONS_LIST, getSuggestionSaga);
  yield takeEvery(EXPORT_SEGGESTION_DATA, exportSuggestionExcel);
}



function* SuggestionSaga() {
  yield all([
    fork(watchgetSuggestionSaga),
  ]);
}

export default SuggestionSaga;
