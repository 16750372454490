import {
   
    INACTIVE_USER,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    REACTIVE_USER
  } from "./actionType";
  
  // common success

  export const HumanityApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  export const HumanityApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getInactiveMembersRequest = payload => ({
    type: INACTIVE_USER,
    payload,
  });

  export const re_activateMemberRequest = payload => ({
    type: REACTIVE_USER,
    payload,
  });


  