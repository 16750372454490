import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Ecoomerce Redux States
import {
    EVENT_LIST,

} from "./actionType";

import {
  SuggestionApiResponseSuccess,
  SuggestionApiResponseError,
  
} from "./action";

//Include Both Helper File with needed methods
import {
    EventsListAPI,
} from "../../helpers/fakebackend_helper";

function* getEventListSaga({payload: {payload, onFinally, onSuccess} = {},
}) {
    console.log("calling getEventListSaga",payload);
  try 
  {
    const response = yield call(EventsListAPI,payload);
    onSuccess(response);
  
  } catch (error) {
      console.log(error);
  }
}



export function* watchEventSaga() {
  yield takeEvery(EVENT_LIST, getEventListSaga);
}



function* EventSaga() {
  yield all([
    fork(watchEventSaga),
  ]);
}

export default EventSaga;
