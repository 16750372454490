import React, { useState, useEffect,useCallback }  from 'react';
import { Link } from 'react-router-dom';
import { Button,Card, CardBody, CardHeader, Col, Container, Row ,Modal, ModalHeader, ModalBody, Toast, ToastBody, ToastHeader,Input,Label} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { getSuggestionRequest ,exportSuggestiondata} from "../../store/suggestion/action";
import {useDispatch} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import moment  from 'moment';
import Select from "react-select";

const SuggestionsList = () => {
 
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rows,setRows]=useState([]);
    const [reactiveID,setReactiveID]=useState();
    
    const countPerPage = 10;


    const columns = [
      
      {
        name: 'Suggestion',
        selector: (row, i) => row.description
        
      },
      {
        name: 'Date',
        selector: (row, i) => moment(row.createdDate).format("DD-MM-YYYY") 
      },
      {
        name: 'By',
        selector: (row, i) => row.usrname
      },
      
      {
        name: 'Sender Mobile',
        selector: (row, i) => row.usrmob
      },
      {
        name: 'Block',
        selector: (row, i) => row.blockname
      },
      {
        name: 'State',
        selector: (row, i) => row.statename
      },
      
    ];

    const [modal_center, setmodal_center] = useState(false);
    const [searchdata, setSearchdata] = useState("");
    const [todate, setTodate] = useState("");
    const [fromdate, setFromdate] = useState("");
    const [gender, setGender] = useState("");
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const handleButtonClick = (e, row) => {
      //e.preventDefault();
      setRows(e);
      setmodal_center(!modal_center);
    };


 

   
    const getUserList= useCallback(()=>{
     
   
        dispatch(
          
          getSuggestionRequest(
              {payload:{ pageno: page,countPerPage:countPerPage,searchdata:searchdata,todate:todate,fromdate:fromdate,gender:gender},
              onSuccess: (response) => {
               // console.log("response data",response.data);
                if (response.data) {
                  setData(response);
                  console.log(response)
               //   pageRef.current = listdata[listdata.length - 1].sync_Timestamp;
                } else {
                //  hasMoreRef.current = false;
                }
              },
              onFinally: () => {
                //setLoading(false);
                //setRefreshing(false);
                console.log("final called");
              },
            }),
         );
    },[dispatch,page,searchdata,gender,fromdate,todate],
       
    );
 
      useEffect(() => {
        getUserList();
      }, [getUserList]);

    const _Updategender=sex=>
    {
       setGender(sex);
    }

    const _downloadExcel=()=>{
      dispatch(
          
        exportSuggestiondata(
            {payload:{ pageno: page,countPerPage:countPerPage,searchdata:searchdata,todate:todate,fromdate:fromdate,gender:gender},
            onSuccess: (response) => {
            
              let fileName = 'NaamJaamReport.xlsx';
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE variant
                window.navigator.msSaveOrOpenBlob(
                  new Blob([response], {
                    type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                  }),
                  fileName
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response], {
                    type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                  })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
              //  link.remove();
              }
              if (response.data) {
               // setData(response);
              //  console.log(response)
             //   pageRef.current = listdata[listdata.length - 1].sync_Timestamp;
              } else {
              //  hasMoreRef.current = false;
              }
            },
            onFinally: () => {
              //setLoading(false);
              //setRefreshing(false);
              console.log("final called");
            },
          }),
       );
    }
    const _updateFromdate=fdate=>
    {
      
      setFromdate(moment(fdate).format("YYYY-MM-DD"));
    }
    const _updateTodate=fdate=>
    {
     
      setTodate(moment(fdate).format("YYYY-MM-DD"));
    }

    document.title = "Suggestions List | HUManITy";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Suggestions List" pageTitle="Home" />
                            
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Filter And Export Suggestions List</h5>
                                </CardHeader>
                              
                                <CardBody>
                               
                                <Row className="g-4 mb-3">
                                <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">Gender</label></div>
                                  <select className="form-select mb-3" aria-label="Default select example" onChange={e=>_Updategender(e.target.value)} >
                                      <option defaultValue="all">Select gender </option>
                                      <option defaultValue="male">Male</option>
                                      <option defaultValue="female">Female</option>
                                      <option defaultValue="sukhdua">Sukhdua</option>
                                  </select>
                              </Col>
                                <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">From Date</label></div>
                                <Input id="fromdate" type="date" className="form-control form-control" onChange={e=>_updateFromdate(e.target.value)} />
                                </Col>
                                <Col xxl={2} md={4}>
                                <div className="col-md-6 col-xxl-12"><label htmlFor="exampleInputdate" className="form-label form-label">To Date</label></div>
                                <Input id="todate" type="date" className="form-control form-control" onChange={e=>_updateTodate(e.target.value)} />
                                </Col>
                                </Row>
                            
                                <Row className="g-4 mb-3">
                                            
                                <Col className="col-sm-auto">
                                                <div>
                                                    <Button color="success" className="add-btn me-1"  id="create-btn" style={{backgroundColor:'#00733b'}} onClick={e=>_downloadExcel()}><i className="ri-download-line align-bottom me-1"></i> Excel</Button>
                                                    
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                               
                                           
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." onChange={e => setSearchdata(e.target.value)} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                <DataTable
                                    columns={columns}
                                    data={data.data}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={data.totalrows}
                                    paginationPerPage={countPerPage}
                                    paginationComponentOptions={{
                                    noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </div>
            <Modal
        isOpen={modal_center}
        toggle={() => {
            tog_center();
        }}
        centered
    >
      
        <ModalHeader>
           
        </ModalHeader>
        <div className="modal-body text-center p-5">
        <img height="150px" width="150px" alt={rows.usrname} src={"https://fileupload.dsshumanityapp.com/profile/"+rows.usrid+".jpg"} />
            <div className="mt-4">
            <h4 className="mb-3">{rows.usrname}</h4>
                <h5 className="mb-3">Do you want to Re-Activate this?</h5>
                <p className="text-muted mb-4"> Once You will re-active.Member will able to login.</p>
                <div className="hstack gap-2 justify-content-center">
                    <Button color="light" onClick={() => setmodal_center(false)}>No</Button>
                   
                </div>
            </div>
        </div>
    </Modal>
        </React.Fragment>
    );
};

export default SuggestionsList;
